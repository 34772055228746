import React from 'react';
import './index.css'; // Ensure this imports the updated CSS

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; 2023 HSX Technologies Private Limited. All rights reserved.</p> {/* Updated legal name */}
    </footer>
  );
};

export default Footer;