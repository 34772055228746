import React, {forwardRef, useState} from 'react';
import { Carousel } from 'react-responsive-carousel'; // Correct named import
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Importing carousel styles
import './index.css'; // Importing custom styles

const Home = forwardRef((props, ref) => {
  const [images] = useState([
    "https://images.unsplash.com/photo-1573495612522-9e401cc84a4f?q=80&w=2369&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1573166364366-3f4f8b1857ea?q=80&w=2369&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1573166364266-356ef04ae798?q=80&w=2369&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1573165759995-5865a394a1aa?q=80&w=2369&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1573497701175-00c200fd57f0?q=80&w=2369&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  ]);
  return (
    <main className="home" ref={ref}>
            <div className="carousel-container">

      <Carousel className="carousel" 
        autoPlay 
        infiniteLoop 
        showArrows={true} 
        showThumbs={false} 
        height={'90%'}
        dynamicHeight={false}>
          {
            images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Software Development ${index + 1}`} />
              </div>
            ))
          }
      </Carousel>
      <h2 className="carousel-title">Welcome to Our IT Services & Consultancy</h2>
</div>
    </main>
  );
});

export default Home;