import React, { forwardRef, useState } from 'react';
import './index.css';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <div
        className={`faq-question ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

const FAQs = forwardRef((props, ref) => {
  const faqData = [
    {
      question: "What services do you offer?",
      answer: "We offer a wide range of services including Web Development, Backend Solutions, Mobile Apps, Desktop Applications, Cloud Services, Cybersecurity, AI and Machine Learning, Quantum Computing, Blockchain, Cloud-Native Development, Low-Code/No-Code Platforms, Edge Computing, Microservices, Zero-Trust Architectures, DevOps, AR/VR Software, NLP and Conversational AI, Autonomous Systems, IoT Platforms, Digital Twins, and Multi-Experience Development Platforms."
    },
    {
      question: "How do you ensure the security of my data?",
      answer: "We implement comprehensive security measures including vulnerability assessments, penetration testing, and security audits. Our cybersecurity services also include zero-trust architectures, identity and access management, and continuous monitoring to safeguard your digital assets."
    },
    {
      question: "Can you help with cloud migration?",
      answer: "Yes, we offer cloud migration services using AWS, Azure, and Google Cloud. Our solutions include infrastructure setup, managed services, and ensuring your business operations run smoothly in the cloud."
    },
    {
      question: "What technologies do you use for web development?",
      answer: "We use the latest technologies like React, Angular, and Vue to build responsive and dynamic websites. Our team ensures that your website is optimized for performance and SEO."
    },
    {
      question: "Do you offer mobile app development?",
      answer: "Yes, we develop cross-platform mobile applications for iOS and Android using Flutter and React Native. Our apps are designed to be user-friendly and performant, ensuring a consistent experience across both platforms."
    },
    {
      question: "What is your approach to AI and Machine Learning?",
      answer: "We develop AI and ML models using TensorFlow, PyTorch, and other frameworks to solve complex problems and automate processes. Our solutions include predictive analytics, natural language processing, and computer vision."
    },
    {
      question: "How do you handle DevOps and CI/CD?",
      answer: "We implement CI/CD pipelines using tools like Jenkins, GitLab CI, and CircleCI. Our services include automation, monitoring, and infrastructure management to ensure efficient and automated software delivery."
    },
    {
      question: "Can you develop AR and VR applications?",
      answer: "Yes, we develop AR and VR applications using Unity and Unreal Engine. Our solutions include training simulations, virtual tours, and interactive experiences."
    },
    {
      question: "What is your experience with blockchain technology?",
      answer: "We build blockchain applications using Ethereum, Hyperledger, and other platforms. Our services include smart contract development, decentralized finance (DeFi) solutions, and supply chain transparency."
    },
    {
      question: "Do you offer support and maintenance services?",
      answer: "Yes, we offer various support and maintenance packages to keep your applications up-to-date, secure, and running smoothly. We can discuss these options based on your specific needs."
    },
    {
      question: "How do you approach cloud-native development?",
      answer: "We develop cloud-native applications using microservices architecture and manage infrastructure using IaC tools like Terraform and AWS CloudFormation. Our solutions ensure scalability, reliability, and efficiency."
    },
    {
      question: "What is your pricing structure?",
      answer: "Our pricing is project-based and depends on the specific requirements of each client. We provide detailed quotes after our initial consultation and requirements gathering phase."
    },
    {
      question: "Can you help with search engine optimization (SEO)?",
      answer: "Absolutely! We implement SEO best practices in all our web development projects. We also offer additional SEO services to help improve your website's visibility and ranking in search engines."
    },
    {
      question: "What is your process for developing mobile applications?",
      answer: "Our mobile app development process includes initial consultation, requirements gathering, design, development, testing, and deployment. We ensure that the app is user-friendly, performant, and meets your business needs."
    },
    {
      question: "How do you ensure the quality of your software?",
      answer: "We follow best practices in software development, including code reviews, automated testing, and continuous integration. Our quality assurance team conducts thorough testing to ensure that the software is reliable and bug-free."
    },
    {
      question: "Can you integrate third-party services into my application?",
      answer: "Yes, we can integrate various third-party services and APIs into your application to enhance its functionality. This includes payment gateways, social media platforms, analytics tools, and more."
    },
    {
      question: "What is your experience with quantum computing?",
      answer: "Our quantum computing services include algorithm development, quantum simulation, and optimization using platforms like IBM Q and Microsoft Quantum. We help you explore the potential of quantum computing for your business."
    },
    {
      question: "How do you handle data privacy and compliance?",
      answer: "We adhere to industry standards and regulations for data privacy and compliance, including GDPR, HIPAA, and CCPA. Our team ensures that your data is handled securely and in compliance with relevant laws."
    },
    {
      question: "What is your approach to project management?",
      answer: "We use agile methodologies to manage projects, ensuring flexibility and transparency throughout the development process. Our project managers work closely with you to ensure that the project stays on track and meets your requirements."
    },
    {
      question: "Can you provide training and support for my team?",
      answer: "Yes, we offer training and support services to help your team get up to speed with new technologies and tools. This includes workshops, documentation, and ongoing support to ensure a smooth transition."
    },
    {
      question: "How do you handle scalability for applications?",
      answer: "We design applications with scalability in mind, using microservices architecture, containerization, and cloud-native technologies. This ensures that your application can handle increased traffic and grow with your business."
    },
    {
      question: "What is your experience with IoT platforms?",
      answer: "We build IoT platforms using AWS IoT, Azure IoT, and Google Cloud IoT. Our services include device management, data analytics, and integration with existing systems to create comprehensive IoT solutions."
    },
    {
      question: "How do you approach digital twin development?",
      answer: "We develop digital twin applications using platforms like Siemens MindSphere and GE Predix. Our solutions include real-time data visualization, predictive maintenance, and process optimization to enhance your operations."
    },
    {
      question: "What is your experience with multi-experience development platforms (MXDP)?",
      answer: "We use MXDPs like Mendix and OutSystems to build applications that provide a seamless user experience across web, mobile, and other platforms. Our services include custom development, integration, and support."
    }
  ];

  return (
    <div ref={ref} className="faqs">
      <h2>Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
});

export default FAQs;