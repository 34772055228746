import React, { useState } from 'react';
import './index.css';

const EventPage = () => {
  const [expandedBios, setExpandedBios] = useState({});

  const toggleBio = (index) => {
    setExpandedBios(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const events = [
    {
      title: 'Annual Company Conference',
      date: '2024-06-15',
      description: 'Join us for our annual company-wide conference featuring keynote speakers, workshops, and networking opportunities.',
      location: 'Grand Hotel, Downtown',
      speakers: [
        { name: 'Shashank', bio: 'CEO and Founder' },
        { name: 'Umesh', bio: 'Industry Expert' }
      ]
    },
    {
      title: 'Product Launch Webinar',
      date: '2024-08-01',
      description: 'Be the first to see our new product line and learn about its innovative features.',
      location: 'Online',
      speakers: [
        { name: 'Abhimanyu Jataria', bio: 'Head of Product Development' }
      ]
    }
  ];

  return (
    <div className="events-container">
      {events.map((event, index) => (
        <div key={index} className="event-card">
          <h2 className="event-title">{event.title}</h2>
          <p className="event-date">{event.date}</p>
          <p className="event-location">{event.location}</p>
          <p className="event-description">{event.description}</p>
          <div className="event-speakers">
            <h3>Speakers:</h3>
            {event.speakers.map((speaker, speakerIndex) => (
              <div key={speakerIndex} className="speaker">
                <p className="speaker-name">{speaker.name}</p>
                <button onClick={() => toggleBio(`${index}-${speakerIndex}`)}>
                  {expandedBios[`${index}-${speakerIndex}`] ? 'Hide Bio' : 'Show Bio'}
                </button>
                {expandedBios[`${index}-${speakerIndex}`] && (
                  <p className="speaker-bio">{speaker.bio}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventPage;