import React, { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faComment, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import './index.css';
import { contactRef } from '../refs';

const Contact = forwardRef((props, ref) => {
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    try {
      const response = await fetch(form.action, {
        method: form.method,
        body: data,
        headers: {
            'Accept': 'application/json'
        }
      });
      const result = await response.json();
      if (response.ok) {
        setStatus("SUCCESS");
        form.reset();
      } else {
        setStatus("ERROR");
        console.error(result);
      }
    } catch (error) {
      setStatus("ERROR");
      console.error(error);
    }
    handleLearnMore();
  }
  const handleLearnMore = () => {
    if (contactRef.current) {
      const yOffset = +40; // 40px offset
      const y = contactRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <div className="contact" ref={ref}>
      <h2>Get in Touch</h2>
      <form 
        className="contact-form" 
        onSubmit={handleSubmit}
        action="https://formspree.io/f/mldrvapa"  // Replace with your Formspree endpoint
        method="POST"
      >
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <FontAwesomeIcon icon={faUser} className="input-icon" />
          <input type="text" id="name" name="name" placeholder="Your Name" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
          <input type="email" id="email" name="email" placeholder="Your Email" required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <FontAwesomeIcon icon={faComment} className="input-icon" />
          <textarea id="message" name="message" placeholder="Your Message" required></textarea>
        </div>
        <button type="submit" className="submit-btn" disabled={status === "SUBMITTING"}>
          <FontAwesomeIcon icon={faPaperPlane} className="btn-icon" />
          {status === "SUBMITTING" ? "Sending..." : "Send Message"}
        </button>
      </form>
      {status === "SUCCESS" && <p className="submit-message success">Thanks for your message! We'll get back to you soon.</p>}
      {status === "ERROR" && <p className="submit-message error">Oops! There was an error sending your message. Please try again.</p>}
    </div>
  );
});

export default Contact;