import React, { useState } from 'react';
import './index.css';

const BlogPost = ({ post, onReadMore }) => (
  <div className="blog-post">
    <h2>{post.title}</h2>
    <div className="post-content">
      <p className="post-date">{post.date}</p>
      <p className="post-excerpt">{post.excerpt}</p>
      <button onClick={() => onReadMore(post)} className="read-more">Read More</button>
    </div>
  </div>
);

const Modal = ({ post, onClose }) => (
  <div className="modal-overlay" onClick={onClose}>
    <div className="modal-content" onClick={e => e.stopPropagation()}>
      <h2>{post.title}</h2>
      <p className="post-date">{post.date}</p>
      <div className="full-content">{post.fullContent}</div>
      <button onClick={onClose} className="close-modal">Close</button>
    </div>
  </div>
);

const BlogPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedPost, setSelectedPost] = useState(null);

  const blogPosts = [
    { 
      id: 1, 
      title: 'The Rise of AI in Journalism', 
      excerpt: 'AI is reshaping the way news is gathered and presented. This article explores its impact on modern journalism...', 
      date: '2023-03-10', 
      category: 'Technology',
      fullContent: 'As AI becomes more sophisticated, it is being used in various sectors of journalism, from fact-checking to automated news writing. While this presents opportunities for efficiency, it also raises concerns about job displacement and the authenticity of machine-generated content. In this article, we explore the current state of AI in journalism and what the future holds for this emerging technology.'
    },
    { 
      id: 2, 
      title: 'How Digital Media is Disrupting Traditional News', 
      excerpt: 'The shift from print to digital has caused major disruptions in the news industry. Here’s how...', 
      date: '2023-04-02', 
      category: 'Business',
      fullContent: 'Traditional news outlets have had to adapt quickly as digital media continues to dominate. This shift has led to new business models, such as subscription services and sponsored content, as advertising revenues from print media decline. This blog post delves into the ways digital platforms are reshaping how news is consumed, distributed, and monetized in today’s world.'
    },
    { 
      id: 3, 
      title: 'The Future of News in the Age of Social Media', 
      excerpt: 'Social media has dramatically altered news consumption habits. What does this mean for news organizations?', 
      date: '2023-05-15', 
      category: 'Technology',
      fullContent: 'With the rise of platforms like Twitter, Facebook, and Instagram, news organizations face new challenges in retaining control over the news narrative. Social media allows for instant dissemination of information, but it also opens the door to misinformation. This post investigates the implications of social media on the credibility and reach of traditional news outlets.'
    },
    { 
      id: 4, 
      title: 'The Role of Data Journalism in 2024', 
      excerpt: 'Data journalism is transforming investigative reporting. Learn about its growing importance in modern media...', 
      date: '2023-06-12', 
      category: 'Business',
      fullContent: 'Data journalism involves analyzing large datasets to uncover stories and trends that might not be immediately apparent. This method of reporting has become increasingly valuable in fields like politics, finance, and health, providing readers with insights backed by hard data. This article examines how data journalism is used and the skills journalists need to stay competitive in the industry.'
    },
    { 
      id: 5, 
      title: 'Challenges Facing News Media in the Digital Era', 
      excerpt: 'News organizations are navigating a complex digital landscape with both opportunities and challenges...', 
      date: '2023-07-08', 
      category: 'Business',
      fullContent: 'From declining print circulation to the rise of ad-blockers, news media companies face numerous hurdles in the digital age. Despite the growth of online readership, monetization remains a critical challenge. This blog post explores the major issues news organizations face and how they are innovating to survive in a competitive digital market.'
    },
    { 
      id: 6, 
      title: 'How Fake News is Affecting Public Trust in Media', 
      excerpt: 'Fake news is more prevalent than ever, and it’s eroding public trust. How can we combat this issue?', 
      date: '2023-07-22', 
      category: 'Technology',
      fullContent: 'The rise of fake news has made it more difficult for the public to discern fact from fiction, which in turn has eroded trust in news organizations. This article looks at the causes behind the spread of fake news and the initiatives being taken to prevent it, such as media literacy programs and technological solutions aimed at fact-checking.'
    },
    { 
      id: 7, 
      title: 'The Impact of Streaming News on Traditional TV', 
      excerpt: 'As more people turn to streaming platforms for news, traditional TV is facing increased competition...', 
      date: '2023-08-05', 
      category: 'Business',
      fullContent: 'Streaming platforms like YouTube and social media giants like Facebook have become significant players in the news industry. With the younger generation increasingly consuming news via streaming, traditional TV news outlets are scrambling to stay relevant. This blog post discusses the shift and how media companies are pivoting their strategies in response.'
    },
    { 
      id: 8, 
      title: 'The Ethics of News Reporting in Crisis Situations', 
      excerpt: 'Reporting during crises requires delicate ethical considerations. Here’s how news outlets are adapting...', 
      date: '2023-08-20', 
      category: 'Business',
      fullContent: 'When covering crises such as wars, natural disasters, or pandemics, news organizations face ethical challenges in balancing the need for information with respect for those affected. This article discusses the responsibilities of journalists during such events, including avoiding sensationalism and ensuring accuracy, even in rapidly changing situations.'
    },
    { 
      id: 9, 
      title: 'How News Apps Are Changing News Consumption', 
      excerpt: 'With the rise of mobile news apps, readers can now access content anytime, anywhere. What does this mean for the industry?', 
      date: '2023-09-01', 
      category: 'Technology',
      fullContent: 'Mobile apps have revolutionized how people consume news, offering personalized content and notifications that keep users engaged throughout the day. This blog post examines the growing popularity of news apps and how news organizations are capitalizing on this trend to deliver content more effectively and monetize their digital platforms.'
    },
    { 
      id: 10, 
      title: 'The Role of Podcasts in News Media', 
      excerpt: 'Podcasts have emerged as a powerful medium for news organizations to reach new audiences...', 
      date: '2023-09-10', 
      category: 'Technology',
      fullContent: 'Podcasts are quickly becoming a staple in the news industry, allowing organizations to produce long-form content that engages listeners on a deeper level. This post explores the rise of podcasts as a platform for news dissemination and how news organizations are using this medium to attract younger, more tech-savvy audiences.'
    }
  ];
  

  const categories = ['All', ...new Set(blogPosts.map(post => post.category))];

  const filteredPosts = blogPosts.filter(post => 
    (selectedCategory === 'All' || post.category === selectedCategory) &&
    (post.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
     post.excerpt.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleReadMore = (post) => {
    setSelectedPost(post);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  return (
    <div className="blog-page">
      <h1>Company Blog</h1>
      <div className="blog-controls">
        <input 
          type="text" 
          placeholder="Search posts..." 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <select 
          value={selectedCategory} 
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="category-select"
        >
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>
      <div className="blog-list">
        {filteredPosts.length > 0 ? (
          filteredPosts.map(post => (
            <BlogPost key={post.id} post={post} onReadMore={handleReadMore} />
          ))
        ) : (
          <p className="no-results">No blog posts found.</p>
        )}
      </div>
      {selectedPost && (
        <Modal post={selectedPost} onClose={closeModal} />
      )}
    </div>
  );
};

export default BlogPage;
