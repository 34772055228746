import React, { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faServer, faMobileAlt, faDesktop, faCloud, faLock, faArrowRight, faRobot, faNetworkWired, faCubes, faCloudUploadAlt, faProjectDiagram, faShieldAlt, faSyncAlt, faVrCardboard, faLanguage, faMicrochip, faSatellite, faBroadcastTower, faDigitalTachograph, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import './index.css';

Modal.setAppElement('#root'); // Set the root element for accessibility

const Services = forwardRef(({ isDarkMode }, ref) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    { 
      icon: faCode, 
      title: "Web Development", 
      description: "Custom websites tailored to your needs, focusing on user experience and performance.",
      details: "We use the latest technologies like React, Angular, and Vue to build responsive and dynamic websites. Our team ensures that your website is optimized for performance and SEO."
    },
    { 
      icon: faServer, 
      title: "Backend Solutions", 
      description: "Robust server-side applications and APIs to power your digital products.",
      details: "Our backend solutions are built using Node.js, Python, and Java. We ensure that your server-side applications are scalable, secure, and efficient."
    },
    { 
      icon: faMobileAlt, 
      title: "Mobile Apps", 
      description: "Cross-platform mobile applications for iOS and Android, delivering seamless experiences.",
      details: "We develop mobile apps using Flutter and React Native, ensuring a consistent experience across both iOS and Android platforms. Our apps are designed to be user-friendly and performant."
    },
    { 
      icon: faDesktop, 
      title: "Desktop Applications", 
      description: "Powerful desktop software for Windows, macOS, and Linux platforms.",
      details: "Our desktop applications are built using Electron and .NET, providing a native-like experience on all major operating systems. We focus on creating robust and feature-rich applications."
    },
    { 
      icon: faCloud, 
      title: "Cloud Services", 
      description: "Scalable cloud solutions to enhance your business operations and data management.",
      details: "We offer cloud services using AWS, Azure, and Google Cloud. Our solutions include cloud migration, infrastructure setup, and managed services to ensure your business runs smoothly."
    },
    { 
      icon: faLock, 
      title: "Cybersecurity", 
      description: "Comprehensive security measures to protect your digital assets and user data.",
      details: "Our cybersecurity services include vulnerability assessments, penetration testing, and security audits. We help you safeguard your digital assets against potential threats."
    },
    { 
      icon: faRobot, 
      title: "Artificial Intelligence and Machine Learning", 
      description: "Advanced AI and ML solutions to drive innovation and efficiency.",
      details: "We develop AI and ML models using TensorFlow, PyTorch, and other frameworks to solve complex problems and automate processes. Our solutions include predictive analytics, natural language processing, and computer vision."
    },
    { 
      icon: faMicrochip, 
      title: "Quantum Computing in Software", 
      description: "Harnessing the power of quantum computing for groundbreaking applications.",
      details: "Our quantum computing services include algorithm development, quantum simulation, and optimization using platforms like IBM Q and Microsoft Quantum. We help you explore the potential of quantum computing for your business."
    },
    { 
      icon: faCubes, 
      title: "Blockchain and Decentralized Applications (dApps)", 
      description: "Secure and transparent blockchain solutions for various industries.",
      details: "We build blockchain applications using Ethereum, Hyperledger, and other platforms. Our services include smart contract development, decentralized finance (DeFi) solutions, and supply chain transparency."
    },
    { 
      icon: faCloudUploadAlt, 
      title: "Cloud-Native Development and Infrastructure as Code (IaC)", 
      description: "Modern cloud-native applications and automated infrastructure management.",
      details: "We develop cloud-native applications using microservices architecture and manage infrastructure using IaC tools like Terraform and AWS CloudFormation. Our solutions ensure scalability, reliability, and efficiency."
    },
    { 
      icon: faProjectDiagram, 
      title: "Low-Code/No-Code Platforms", 
      description: "Rapid application development with low-code/no-code platforms.",
      details: "We leverage platforms like OutSystems, Mendix, and Microsoft PowerApps to build applications quickly and efficiently. Our services include custom development, integration, and training."
    },
    { 
      icon: faSatellite, 
      title: "Edge Computing and Distributed Systems", 
      description: "Optimized computing at the edge for real-time data processing.",
      details: "We develop edge computing solutions using platforms like AWS Greengrass and Azure IoT Edge. Our services include distributed system design, data processing, and real-time analytics."
    },
    { 
      icon: faBroadcastTower, 
      title: "Microservices and Containerization (Kubernetes, Docker)", 
      description: "Scalable and resilient microservices architecture with containerization.",
      details: "We design and implement microservices using Docker and Kubernetes. Our services include container orchestration, service mesh implementation, and CI/CD pipeline setup."
    },
    { 
      icon: faShieldAlt, 
      title: "Cybersecurity and Zero-Trust Architectures", 
      description: "Advanced security measures with zero-trust principles.",
      details: "We implement zero-trust architectures to enhance security. Our services include identity and access management, network segmentation, and continuous monitoring."
    },
    { 
      icon: faSyncAlt, 
      title: "DevOps and Continuous Integration/Continuous Delivery (CI/CD)", 
      description: "Efficient and automated software delivery with DevOps practices.",
      details: "We implement CI/CD pipelines using tools like Jenkins, GitLab CI, and CircleCI. Our services include automation, monitoring, and infrastructure management."
    },
    { 
      icon: faVrCardboard, 
      title: "Augmented Reality (AR) and Virtual Reality (VR) Software", 
      description: "Immersive AR and VR experiences for various applications.",
      details: "We develop AR and VR applications using Unity and Unreal Engine. Our solutions include training simulations, virtual tours, and interactive experiences."
    },
    { 
      icon: faLanguage, 
      title: "Natural Language Processing (NLP) and Conversational AI", 
      description: "Advanced NLP and conversational AI solutions for better communication.",
      details: "We build NLP models and chatbots using platforms like Dialogflow and Rasa. Our services include sentiment analysis, language translation, and voice recognition."
    },
    { 
      icon: faMicrochip, 
      title: "Autonomous Systems and Software for Robotics", 
      description: "Innovative software solutions for autonomous systems and robotics.",
      details: "We develop software for autonomous vehicles, drones, and industrial robots. Our services include path planning, sensor integration, and real-time control."
    },
    { 
      icon: faNetworkWired, 
      title: "Internet of Things (IoT) Software Platforms", 
      description: "Comprehensive IoT solutions for connected devices and systems.",
      details: "We build IoT platforms using AWS IoT, Azure IoT, and Google Cloud IoT. Our services include device management, data analytics, and integration with existing systems."
    },
    { 
      icon: faDigitalTachograph, 
      title: "Software for Digital Twins", 
      description: "Digital twin solutions for real-time monitoring and simulation.",
      details: "We develop digital twin applications using platforms like Siemens MindSphere and GE Predix. Our solutions include real-time data visualization, predictive maintenance, and process optimization."
    },
    { 
      icon: faLaptopCode, 
      title: "Multi-Experience Development Platforms (MXDP)", 
      description: "Unified development platforms for multi-experience applications.",
      details: "We use MXDPs like Mendix and OutSystems to build applications that provide a seamless user experience across web, mobile, and other platforms. Our services include custom development, integration, and support."
    },
  ];

  const handleLearnMore = (service) => {
    setSelectedService(service);
  };

  const closeModal = () => {
    setSelectedService(null);
  };

  return (
    <section className={`services ${isDarkMode ? 'dark-mode' : 'light-mode'}`} ref={ref} aria-labelledby="services-title">
      <h2 id="services-title">Our Services</h2>
      <div className="service-grid">
        {services.map((service, index) => (
          <div
            key={index}
            className={`service-item ${hoveredIndex === index ? 'hovered' : ''}`}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div className="service-content">
              <div className="service-icon-wrapper">
                <FontAwesomeIcon icon={service.icon} className="service-icon" />
              </div>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
            <button className="learn-more-btn" onClick={() => handleLearnMore(service)}>
              Learn More
              <FontAwesomeIcon icon={faArrowRight} className="btn-icon" />
            </button>
          </div>
        ))}
      </div>
      <Modal
        isOpen={!!selectedService}
        onRequestClose={closeModal}
        contentLabel="Service Details"
        className="modal"
        overlayClassName="modal-overlay"
      >
        {selectedService && (
          <div>
            <button className="modal-close" onClick={closeModal}>×</button>
            <h2>{selectedService.title}</h2>
            <p>{selectedService.description}</p>
            <p>{selectedService.details}</p>
            {/* Add more detailed information about the service here */}
          </div>
        )}
      </Modal>
    </section>
  );
});

export default Services;