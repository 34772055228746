import React, { useState } from 'react';
import './index.css';

const teamMembers = [
  { 
    name: 'K. Sharma', 
    role: 'Director', 
    location: 'India',
    bio: 'Innovative leader with over 5 years of experience in crafting cutting-edge solutions that address complex challenges. With a background at prestigious organizations such as Y Combinator and Morgan Stanley, combines the agility of startups with the rigor of corporate environments. Driven by a passion for technology and its potential to transform industries, K. specializes in developing scalable and efficient systems that enhance operational success. Known for a visionary mindset and a proven record of impactful contributions, K. is dedicated to fostering innovation and driving meaningful change within the tech landscape.',
    // avatar: 'https://example.com/john-doe-avatar.jpg' // Replace with actual avatar URL
  },
  { 
    name: 'S. Chauhan', 
    role: 'CEO', 
    location: 'US',
    bio: 'Experienced industry professional with over 6 years of expertise, having worked with top companies like Morgan Stanley, JP Morgan & Chase, HighLevel, and Snaptrude. With a strong background in technology and business strategy, specializes in delivering innovative solutions that drive growth and efficiency across sectors',
    // avatar: 'https://example.com/jane-smith-avatar.jpg' // Replace with actual avatar URL
  },
  { 
    name: 'U. Palav', 
    role: 'CTO', 
    location: 'India',
    bio: 'Skilled technology professional with 6 years of experience, having worked at Morgan Stanley, Accolite, and The Branch International. With a strong foundation in software development and technology solutions, Consistently delivered high-quality results across various projects. Their expertise spans both corporate and startup environments, making them adept at navigating complex technical challenges while driving innovation and efficiency.',
    // avatar: 'https://example.com/mike-johnson-avatar.jpg' // Replace with actual avatar URL
  },
  { 
    name: 'A. Jataria', 
    role: 'Engineering Director', 
    location: 'US',
    bio: 'Worked at Nutanix, VMWare, and currently at Yext. Has a passion for building products that make a difference. Has been in the industry for over 10 years.',
    // avatar: 'https://example.com/mike-johnson-avatar.jpg' // Replace with actual avatar URL
  },
  {
    name: 'A. Gupta',
    role: 'Engineering Architect',
    location: 'US',
    bio: 'Renowned for his expertise in building scalable and secure systems, Has a proven track record of delivering high-quality software solutions. With a focus on innovation and efficiency, Gupta has consistently delivered impactful contributions to projects, driving growth and success in the industry.',
  },
  {
    name: 'K. Kant',
    role: 'Staff Engineer',
    location: 'US',
    bio: 'Kant is a seasoned software engineer with over 10 years of experience in the industry. With a strong background in building scalable and secure systems, Kant has consistently delivered impactful contributions to projects, driving growth and success in the industry.',
  },
  {
    name: 'S. Makhija',
    role: 'Staff Engineer',
    location: 'US',
    bio: 'Worked with like-minded people at Google. Has a passion for building products that make a difference. Has been in the industry for over 10 years.',
  }
  // Add more team members as needed
];

const Directory = () => {
  const [expandedBios, setExpandedBios] = useState({});

  const toggleBio = (index) => {
    setExpandedBios(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  return (
    <div className="directory">
      <h1>Our Team</h1>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div key={index} className="member-card">
            {member.avatar ? (
              <img src={member.avatar} alt={`${member.name}'s avatar`} className="member-avatar" />
            ) : (
              <div className="member-avatar-placeholder">
                {member.name.split(' ').map(n => n[0]).join('').toUpperCase()}
              </div>
            )}
            <div className="member-info">
              <h2>{member.name}</h2>
              <h3>{member.role}</h3>
              <h3>{member.location}</h3>
              <p>
                {expandedBios[index] ? member.bio : `${member.bio.slice(0, 100)}...`}
                <button onClick={() => toggleBio(index)} className="read-more-btn">
                  {expandedBios[index] ? 'Read Less' : 'Read More'}
                </button>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Directory;
