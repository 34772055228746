import React, { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faServer, faMobileAlt, faDesktop, faCloud, faLock, faArrowRight, faGlobe, faNewspaper, faRobot } from '@fortawesome/free-solid-svg-icons';
import './index.css';

const products = {
	tbn247: {
		id: 'tbn247',
		title: "tbn247 News Portal",
		description: "Premier online news portal based in South Asia, delivering real-time news and insights across various topics.",
		features: [
			{ icon: faGlobe, text: "24/7 news coverage with a focus on South Asia" },
			{ icon: faNewspaper, text: "Expanding into Europe with AI-driven insights" },
			{ icon: faDesktop, text: "Responsive design for seamless browsing" },
			{ icon: faCloud, text: "Real-time updates on regional affairs" },
		],
		image: require('../../icons/images/tbn247.png'),
		link: "https://tbn247.com/"
	},
	aiProduct: {
		id: 'aiProduct',
		title: "AI-Powered Analytics",
		description: "Cutting-edge AI solution for data analysis and predictive modeling across various industries.",
		features: [
			{ icon: faRobot, text: "Advanced machine learning algorithms" },
			{ icon: faCloud, text: "Cloud-based processing for scalability" },
			{ icon: faLock, text: "Robust data security and privacy measures" },
			{ icon: faServer, text: "Real-time data processing and insights" },
		],
		image: require('../../icons/images/ai-product.png'),
		link: "https://hsxtechnologies.com/#products"
	},
  tqm: {
		id: 'tqm',
		title: "Total Quality Management",
		description: "A comprehensive management strategy aimed at achieving long-term success by ensuring exceptional customer satisfaction through the consistent delivery of high-quality IT services.",
		features: [
			{ icon: faRobot, text: "Cutting-edge machine learning algorithms" },
			{ icon: faCloud, text: "Scalable cloud-based processing solutions" },
			{ icon: faLock, text: "Strong data security and privacy protocols" },
			{ icon: faServer, text: "Instantaneous data processing and actionable insights" },
		],
		image: require('../../icons/images/tqm.png'),
		link: "https://hsxtechnologies.com/#products"
	},
};

const Products = forwardRef(({ isDarkMode }, ref) => {
	const [activeProduct, setActiveProduct] = useState(Object.keys(products)[0]);

	const currentProduct = products[activeProduct];

	return (
		<div ref={ref} className={`products ${isDarkMode ? 'dark-mode' : ''}`}>
			<h2 className='text-center'>Our Products</h2>
			<div className="product-selector">
				{Object.values(products).map((product) => (
					<button
						key={product.id}
						className={`product-selector-btn ${activeProduct === product.id ? 'active' : ''}`}
						onClick={() => setActiveProduct(product.id)}
					>
						{product.title}
					</button>
				))}
			</div>
			<div className="product-container">
				<div className="product-image-container">
					<img 
						src={currentProduct.image} 
						alt={`${currentProduct.title} Logo`} 
            style={{boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.75)'}}
						className="product-image" 
						onError={(e) => e.currentTarget.src = require('../../icons/images/tbn247.png')}
					/>
				</div>
				<div className="product-info">
					<h3 className="product-title">{currentProduct.title}</h3>
					<p className="product-description">{currentProduct.description}</p>
					<ul className="product-features">
						{currentProduct.features.map((feature, index) => (
							<li key={index}>
								<FontAwesomeIcon icon={feature.icon} className="feature-icon" />
								<span>{feature.text}</span>
							</li>
						))}
					</ul>
					<a href={currentProduct.link} target="_blank" rel="noopener noreferrer" className="product-cta">
						Explore {currentProduct.title} <FontAwesomeIcon icon={faArrowRight} />
					</a>
				</div>
			</div>
		</div>
	);
});

export default Products;