import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const jobListings = [
  {
    title: 'Senior Software Engineer',
    department: 'Engineering',
    location: 'Remote',
    type: 'Full-time',
    experience: '5+ years',
    description: 'We are seeking a talented Senior Software Engineer to lead development efforts on our core products. The ideal candidate will have strong experience in full-stack development and a passion for creating scalable, efficient solutions.',
    responsibilities: [
      'Design and implement complex software systems',
      'Lead technical discussions and mentor junior developers',
      'Collaborate with cross-functional teams to define and implement new features',
      'Ensure code quality through code reviews and best practices'
    ],
    requirements: [
      '5+ years of professional software development experience',
      'Strong proficiency in JavaScript, React, and Node.js',
      'Experience with cloud technologies (AWS, Azure, or GCP)',
      'Excellent problem-solving and communication skills'
    ]
  },
  {
    title: 'Product Manager',
    department: 'Product',
    location: 'New York, NY',
    type: 'Full-time',
    experience: '3-5 years',
    description: 'Join our product team to drive the vision and strategy of our cutting-edge products. We\'re looking for a dynamic Product Manager to help shape the future of our platform.',
    responsibilities: [
      'Define product strategy and roadmap',
      'Conduct market research and analyze user feedback',
      'Work closely with engineering and design teams to deliver high-quality products',
      'Prioritize features and create detailed product specifications'
    ],
    requirements: [
      '3-5 years of product management experience in tech',
      'Strong analytical and problem-solving skills',
      'Excellent communication and stakeholder management abilities',
      'Experience with Agile methodologies'
    ]
  },
  // Add more job listings as needed
];

const Careers = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedJob, setExpandedJob] = useState(null);
  const [filteredJobs, setFilteredJobs] = useState(jobListings);
  const [activeFilter, setActiveFilter] = useState('All');
  const [applicationForm, setApplicationForm] = useState({
    name: '',
    email: '',
    phone: '',
    resumeUrl: '',
    message: '',
    jobTitle: ''
  });
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const filtered = jobListings.filter(job =>
      job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.department.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.location.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredJobs(filtered);
  }, [searchTerm]);

  const filterJobs = (filter) => {
    setActiveFilter(filter);
    if (filter === 'All') {
      setFilteredJobs(jobListings);
    } else {
      const filtered = jobListings.filter(job => job.department === filter);
      setFilteredJobs(filtered);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApplicationForm(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/movazwzb', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...applicationForm,
          form: 'job_application'
        }),
      });
      if (response.ok) {
        toast.success('Application submitted successfully!');
        setShowForm(false);
        setApplicationForm({
          name: '',
          email: '',
          phone: '',
          resumeUrl: '',
          message: '',
          jobTitle: ''
        });
      } else {
        toast.error('Failed to submit application. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting application:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="careers">
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <h1>Join Our Team</h1>
      <p className="careers-intro">
        We're always looking for talented individuals to join our team and help shape the future of technology. Explore our open positions and find your next opportunity!
      </p>
      <div className="search-and-filter">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search jobs by title, department, or location"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="filter-buttons">
          {['All', 'Engineering', 'Product', 'Marketing', 'Sales'].map(filter => (
            <button
              key={filter}
              className={`filter-btn ${activeFilter === filter ? 'active' : ''}`}
              onClick={() => filterJobs(filter)}
            >
              {filter}
            </button>
          ))}
        </div>
      </div>
      <div className="job-listings">
        {filteredJobs.map((job, index) => (
          <div key={index} className="job-card">
            <h2>{job.title}</h2>
            <p className="job-details">{job.department} | {job.location} | {job.type}</p>
            <p className="job-experience">Experience: {job.experience}</p>
            <p className="job-description">{job.description}</p>
            {expandedJob === index ? (
              <>
                <h3>Responsibilities:</h3>
                <ul>
                  {job.responsibilities.map((resp, i) => (
                    <li key={i}>{resp}</li>
                  ))}
                </ul>
                <h3>Requirements:</h3>
                <ul>
                  {job.requirements.map((req, i) => (
                    <li key={i}>{req}</li>
                  ))}
                </ul>
                <button className="apply-btn" onClick={() => {
                  setShowForm(true);
                  setApplicationForm(prev => ({ ...prev, jobTitle: job.title }));
                }}>Apply Now</button>
                <button className="toggle-details" onClick={() => setExpandedJob(null)}>Show Less</button>
              </>
            ) : (
              <button className="toggle-details" onClick={() => setExpandedJob(index)}>View Details</button>
            )}
          </div>
        ))}
      </div>
      {filteredJobs.length === 0 && (
        <p className="no-results">No jobs found matching your search. Please try different keywords.</p>
      )}
      
      {showForm && (
        <div className="application-form-overlay">
          <div className="application-form">
            <h2>Apply for {applicationForm.jobTitle}</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={applicationForm.name}
                onChange={handleInputChange}
                placeholder="Full Name"
                required
              />
              <input
                type="email"
                name="email"
                value={applicationForm.email}
                onChange={handleInputChange}
                placeholder="Email"
                required
              />
              <input
                type="tel"
                name="phone"
                value={applicationForm.phone}
                onChange={handleInputChange}
                placeholder="Phone Number"
                required
              />
              <input
                type="url"
                name="resumeUrl"
                value={applicationForm.resumeUrl}
                onChange={handleInputChange}
                placeholder="Resume URL"
                required
              />
              <textarea
                name="message"
                value={applicationForm.message}
                onChange={handleInputChange}
                placeholder="Cover Letter or Additional Information"
                required
              ></textarea>
              <div className="form-buttons">
                <button type="submit" className="submit-btn">Submit Application</button>
                <button type="button" className="cancel-btn" onClick={() => setShowForm(false)}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Careers;
