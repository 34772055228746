import React from 'react';
import './index.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      
      <section className="introduction">
        <h2>Introduction</h2>
        <p>At HSX Technologies Private Limited, we pride ourselves on being a forward-thinking, technology-driven company, pushing the boundaries of what's possible in the digital world. Our journey began with a vision to create products that not only serve the needs of today but also shape the future of media, technology, and communication.</p>
        <p>We are driven by a singular vision—to innovate, inform, and inspire. As a dynamic player in the digital landscape, we leverage cutting-edge technology to create products and solutions that reshape how information is consumed and shared.</p>
      </section>

      <section className="mission">
        <h2>Our Mission</h2>
        <p>Our mission is simple: to transform how the world interacts with digital information, ensuring accessibility, reliability, and excellence in every product and service we deliver. We believe that through technology, we can create a better, more connected world where information flows freely, and innovation knows no limits.</p>
      </section>

      <section className="history">
        <h2>Brief History</h2>
        <p>Founded in 2019, HSX Technologies started as a small team of innovators with a big idea. Since then, we've grown exponentially, thanks to our relentless focus on research and development and our commitment to staying ahead of industry trends. Today, we are a global force in technology, leading the way in media, data-driven solutions, and digital innovation.</p>
      </section>

      <section className="company-values">
        <h2>Our Core Values</h2>
        <p>At HSX Technologies, our core values are the foundation of everything we do:</p>
        <ul>
          <li><strong>Integrity:</strong> We believe in the power of truth. Our commitment to ethical standards and transparency ensures that everything we produce is reliable, authentic, and credible.</li>
          <li><strong>Innovation:</strong> In a rapidly evolving digital world, we are constantly pushing the boundaries of what's possible. From leveraging new technologies to enhancing user experiences, innovation is embedded in our DNA.</li>
          <li><strong>Excellence:</strong> We strive for the highest standards in all our endeavors, whether it's developing cutting-edge products or delivering top-tier content. Every detail matters, and we are relentless in our pursuit of quality.</li>
          <li><strong>Collaboration:</strong> We foster a culture of teamwork and inclusivity, working together across functions to create solutions that are greater than the sum of their parts. At HSX, we believe in the power of diverse perspectives.</li>
          <li><strong>Customer-Centricity:</strong> Our products and services are designed with the end-user in mind. We listen to our customers, understand their needs, and deliver solutions that exceed expectations.</li>
          <li><strong>Agility:</strong> In an ever-changing industry, we are quick to adapt, staying ahead of trends while remaining responsive to the evolving needs of our clients and users.</li>
        </ul>
      </section>

      <section className="integrity-focus">
        <h2>Our Commitment to Integrity</h2>
        <p>At HSX Technologies, integrity is more than just a buzzword—it's a way of doing business. Our commitment to integrity ensures that we uphold the highest ethical standards in every aspect of our operations. From the way we interact with clients and partners to how we manage our internal teams, integrity is the foundation on which we build trust, foster collaboration, and drive success.</p>
      </section>

      <section className="conclusion">
        <p>At HSX Technologies Private Limited, our values are more than just words—they are the guiding principles that shape our approach to business and our commitment to excellence. We believe in the power of technology to transform industries, and we are passionate about creating solutions that make a difference.</p>
      </section>
    </div>
  );
};

export default About;
